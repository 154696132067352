import { combineReducers } from 'redux';
import devices from './devices';
import auth from './auth';
import global from './global';
import users from './users';
import keys from './keys';
import errors from './errors';
import terminal from './terminal';
import clientKey from './clientKey';
import templates from './templates';
import software from './software';
import defaultClient from './defaultClient';
import userSettings from './userSettings';

export default combineReducers({
  devices,
  global,
  errors,
  auth,
  users,
  keys,
  templates,
  terminal,
  software,
  clientKey,
  defaultClient,
  userSettings,
});
