import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row, Switch, Button, Input, Slider, Popconfirm, Popover } from 'antd';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';

import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import DeviceIndicators from '../../components/DeviceIndicators/deviceIndicators';
import upTimeConvertor from '../../utils/upTimeConvertor';
import { notificationSuccess } from '../../utils/default';

const UN_EDITABLE_CONFIGS = ['Uptime', 'Ip', 'Power', 'LastCheck'];
const HIDED_DEFAULT_CONFIGS = ['Uptime', 'Ip', 'GPS', 'LastCheck'];

const DeviceDescription = ({
  selectedDevice,
  headers,
  excludedList,
  editable,
  cancelEditObject,
  editObject,
  changeDeviceHandler,
  changeNotificationHandler,
  changeCurrConf,
  updateDevice,
  highlight,
  updateDefault,
  defaultClient,
  equality,
  setPower,
  setState,
  status,
  auth,
  checkStatus,
  checkCellInfo,
  isDefault,
  exportTemplateFromDevice,
  saveAsTemplate,
}) => {
  const [templateName, setTemplateName] = useState('');
  const changeSwitch = useCallback((state) => setState(selectedDevice?.DeviceID, state), [selectedDevice, setState]);

  const location = useLocation();
  const currentDeviceId = location.pathname.substr(1);

  const scans = useSelector((store) => store.devices?.scans);
  const cellsInfo = useSelector((store) => store.devices?.cellsInfo);
  const software = useSelector((store) => store.devices?.software);

  let newExcludedList = [...excludedList];
  if (isDefault) newExcludedList = [...newExcludedList, ...HIDED_DEFAULT_CONFIGS];

  const statusData = useMemo(() => {
    if (!scans) return null;

    return scans[currentDeviceId];
  }, [scans, currentDeviceId]);

  const cellInfoData = useMemo(() => {
    if (!cellsInfo) return null;

    return cellsInfo[currentDeviceId];
  }, [cellsInfo, currentDeviceId]);

  const softwareData = useMemo(() => {
    if (!software) return null;

    return software[currentDeviceId];
  }, [software, currentDeviceId]);

  const powerOnChange = useCallback(
    debounce((power) => setPower(selectedDevice?.DeviceID, power), 500),
    [selectedDevice, setPower],
  );

  const exportTemplate = () => exportTemplateFromDevice(currentDeviceId);

  const templateNameOnChange = (e) => setTemplateName(e.target.value);

  const saveAsTemplateAction = () => {
    if (templateName) {
      saveAsTemplate(currentDeviceId, templateName).then(() => notificationSuccess('Template saved'));
    }
  };

  return (
    <div className="device-desc">
      <div className="device-desc__upper-actions">
        {!isDefault && (
          <DeviceIndicators
            ApdWifi={selectedDevice?.ApdWifi}
            LastCheck={selectedDevice?.LastCheck}
            status={status}
            DiskUsage={selectedDevice?.DiskUsage}
            deviceId={selectedDevice?.ID}
          />
        )}
      </div>
      <div className="device-desc__header">
        <span>Configuration</span>
        <div className="device-desc__edit" onClick={editable ? cancelEditObject : editObject}>
          {editable ? <CloseOutlined /> : <EditOutlined />}
        </div>
      </div>
      <div className="device-desc__params">
        {selectedDevice &&
          selectedDevice.DeviceID &&
          Object.keys(selectedDevice).map(
            (config) =>
              typeof selectedDevice[config] !== 'object' &&
              !Array.isArray(selectedDevice[config]) &&
              !newExcludedList.includes(config) && (
                <Col key={config} xs={24} md={11}>
                  <div className="device-desc_content">
                    <span className="device-desc_content_name">{config}:</span>
                    {editable ? (
                      config === 'SendNotifications' ? (
                        <div>
                          <Switch
                            onChange={changeNotificationHandler}
                            defaultChecked={selectedDevice[config]}
                            checkedChildren="on"
                            unCheckedChildren="off"
                            name={config}
                          />
                        </div>
                      ) : (
                        <div className="device-desc_content_field ">
                          <Input
                            className={highlight(config).detected && 'error-input'}
                            disabled={UN_EDITABLE_CONFIGS.includes(config)}
                            onChange={changeDeviceHandler}
                            name={config}
                            value={selectedDevice[config]}
                          />
                          <span className="error-message">{highlight(config).err?.Message}</span>
                        </div>
                      )
                    ) : (
                      <span className="device-desc_content_desc">
                        {config === 'Uptime'
                          ? upTimeConvertor(selectedDevice[config].toString())
                          : selectedDevice[config].toString()}
                      </span>
                    )}
                  </div>
                </Col>
              ),
          )}
        {headers &&
          Object.keys(headers).map(
            (config) =>
              typeof headers[config] !== 'object' &&
              !Array.isArray(headers[config]) &&
              !excludedList.includes(config) && (
                <Col key={config} xs={24} md={11}>
                  <div className="device-desc_content">
                    <span className="device-desc_content_name">{config}:</span>
                    {editable ? (
                      <div className="device-desc_content_field">
                        <Input
                          className={highlight(config).detected && 'error-input'}
                          onChange={changeCurrConf}
                          name={config}
                          value={headers[config]}
                        />
                        <span className="error-message">{highlight(config).err?.Message}</span>
                      </div>
                    ) : (
                      <span className="device-desc_content_desc">{headers[config].toString()}</span>
                    )}
                  </div>
                </Col>
              ),
          )}
      </div>
      {!defaultClient && selectedDevice && selectedDevice.DeviceID && (
        <div className="device-desc_actions">
          <Row gutter={24}>
            <Col xs={24} md={24}>
              <Row gutter={24}>
                <Col xs={24} md={4}>
                  <div className="device-desc_actions_item">
                    <span className="device-desc_actions_item_title">State:</span>
                    <span className="device-desc_actions_item_tool">
                      <Switch
                        onChange={changeSwitch}
                        checked={selectedDevice?.State}
                        checkedChildren="on"
                        unCheckedChildren="off"
                      />
                    </span>
                  </div>
                </Col>
                <Col xs={24} md={10}>
                  <div className="device-desc_actions_item">
                    <span className="device-desc_actions_item_title">Power:</span>
                    <span className="device-desc_actions_item_tool">
                      <Slider
                        defaultValue={selectedDevice?.Power}
                        min={0}
                        max={20}
                        step={5}
                        dots
                        onChange={powerOnChange}
                      />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row
                gutter={24}
                style={{ marginTop: 20, display: 'flex' }}
                className="device-desc__button-actions-wrapper"
              >
                {!equality && (
                  <div className="">
                    <Button type="secondary" shape="dashed" disabled={auth?.role_id !== 1} onClick={updateDefault}>
                      Set as Default
                    </Button>
                  </div>
                )}
                {!isDefault && (
                  <div className="" onClick={checkStatus}>
                    <Button disabled={!statusData?.Status || !status} loading={!statusData?.Status}>
                      Wifi Scanning
                    </Button>
                  </div>
                )}

                {!isDefault && (
                  <div className="" onClick={checkCellInfo}>
                    <Button disabled={!cellInfoData?.Status || !status} loading={!cellInfoData?.Status}>
                      Cell Info
                    </Button>
                  </div>
                )}

                {!isDefault && auth?.role_id === 1 && (
                  <Popover
                    content={
                      softwareData?.DeviceID ? (
                        <div className="rwm-device__software-wrapper">
                          {Object.keys(softwareData).map((key) => {
                            return (
                              <div className="rwm-device__software-wrapper_row" key={key}>
                                <span style={{ fontWeight: 700, marginRight: 10 }}>{key}: </span>

                                <span style={{ whiteSpace: 'pre-line' }}>{softwareData[key]}</span>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div>
                          <div>No software applied</div>
                          <Link to="/software">Apply</Link>
                        </div>
                      )
                    }
                    title="Software Info"
                    trigger="click"
                  >
                    <Button disabled={!softwareData} loading={softwareData ? !softwareData?.Status : false}>
                      Software
                    </Button>
                  </Popover>
                )}

                <div className="" onClick={exportTemplate}>
                  <Button>Export Template</Button>
                </div>
                <Popconfirm
                  okText="Save Template"
                  title={<Input placeholder="Template Name" onChange={templateNameOnChange} />}
                  onConfirm={saveAsTemplateAction}
                  okButtonProps={{ disabled: !templateName }}
                >
                  <div className="">
                    <Button>Save as Template</Button>
                  </div>
                </Popconfirm>
              </Row>
            </Col>
            <Col xs={24} md={24}>
              {editable && (
                <div className="update-actions">
                  <Button shape="round" onClick={cancelEditObject}>
                    Cancel
                  </Button>
                  <Button shape="round" onClick={updateDevice} type="primary">
                    Update
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </div>
      )}
      {defaultClient && (
        <div className="device-desc_actions">
          <Row gutter={24}>
            <Col xs={24} md={24}>
              {editable && (
                <div className="update-actions">
                  <Button shape="round" onClick={cancelEditObject}>
                    Cancel
                  </Button>
                  <Button shape="round" onClick={updateDefault} type="primary">
                    Update
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default DeviceDescription;
