import api from '../utils/api';
import { LOGIN, LOGOUT, ERRORS, GET_USER } from '../constants/actionTypes';
import { socket } from '../utils/socketConfig';
import { asyncLocalStorage } from '../utils/asyncLocalStorage';

export const login = (data) => (dispatch) => {
  return api.auth
    .login(data)
    .then(async (res) => {
      dispatch({ type: LOGIN, payload: res.data?.Data });
      await asyncLocalStorage.setItem('token', res.data.Data.Token);
      await asyncLocalStorage.setItem('user', data.username);
      dispatch(getUser());
      dispatch({
        type: ERRORS,
        payload: {},
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err,
      });
      return Promise.reject(err);
    });
};

export const getUser = () => (dispatch) =>
  api.auth
    .getUser()
    .then((res) => {
      localStorage.setItem('roleId', res.data?.Data?.role_id);
      dispatch({
        type: GET_USER,
        payload: res.data?.Data,
      });
      return res.data;
    })
    .catch((err) => err);

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT, payload: {} });
  socket.disconnect();
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};
