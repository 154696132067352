import api from '../utils/api';

import { notificationError, notificationSuccess } from '../utils/default';

import { ERRORS, GET_USER_SETTINGS, SET_USER_SETTINGS } from '../constants/actionTypes';

export const getUserSettings = () => (dispatch) => {
  return api.userSettings
    .get()
    .then((res) => {
      dispatch({
        type: GET_USER_SETTINGS,
        payload: res.data,
      });
      dispatch({
        type: ERRORS,
        payload: {},
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      const status = err?.response?.status;
      status !== 401 && notificationError(null, err?.message);
      dispatch({
        type: ERRORS,
        payload: true,
      });
      return Promise.reject(err);
    });
};

export const setUserSettings = (data) => (dispatch) => {
  return api.userSettings
    .set(data)
    .then((res) => {
      notificationSuccess(null, res.data.Message);
      dispatch({
        type: SET_USER_SETTINGS,
        payload: res.data,
      });
      dispatch({
        type: ERRORS,
        payload: {},
      });
      return res.data;
    })
    .catch((err) => {
      notificationError(null, err?.message);
      dispatch({
        type: ERRORS,
        payload: err,
      });
      return Promise.reject(err);
    });
};
