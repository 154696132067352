import React, { memo, useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'antd';
import { WifiOutlined, DatabaseOutlined, BellOutlined } from '@ant-design/icons';
import moment from 'moment';

import './deviceIndicators.sass';
import { useSelector } from 'react-redux';

const DeviceIndicators = ({ status, ApdWifi, LastCheck, DiskUsage, deviceId }) => {
  const devices = useSelector((store) => store.devices);
  const initDeviceData = devices.Data ? devices.Data.find((device) => device.ID === deviceId) : null;

  const [usageProperties, setUsageProperties] = useState({ color: '#999', message: 'No data' });
  const [notificationStatus, setNotificationStatus] = useState({ color: '#999', message: 'Notifications off' });

  const now = moment(new Date());
  const registration = moment.utc(LastCheck, 'YYYY-MM-DD HH:mm:ss').local();
  const duration = moment.duration(now.diff(registration));

  const lastCheckTime = useMemo(() => {
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let time = undefined;

    if (months > 0) time = <span>{months} months </span>;
    else if (days > 0) time = <span>{days} days </span>;
    else if (hours > 0) time = <span>{hours} hours </span>;
    else if (minutes > 0) time = <span>{minutes} minutes</span>;

    return (
      <div>
        <span>Last online: </span>
        {time}
      </div>
    );
  }, [duration]);

  const checkStorageColor = (usagePercent) => {
    if (usagePercent < 50) {
      setUsageProperties({ color: '#28a745', message: 'Disk is under 50% full' });
    } else if (usagePercent > 49 && usagePercent < 75) {
      setUsageProperties({ color: '#e6cc00', message: 'Disk is over 50% full' });
    } else if (usagePercent > 74) {
      setUsageProperties({ color: '#dc3545', message: 'Disk is over 75% full' });
    } else {
      setUsageProperties({ color: '#999', message: 'No data' });
    }
  };

  const checkNotificationStatus = (status) => {
    if (status === true) {
      setNotificationStatus({ color: '#28a745', message: 'Notifications on' });
    } else {
      setNotificationStatus({ color: '#999', message: 'Notifications off' });
    }
  };

  useEffect(() => {
    checkStorageColor(DiskUsage);
  }, [DiskUsage]);

  useEffect(() => {
    checkNotificationStatus(initDeviceData?.SendNotifications);
  }, [initDeviceData?.SendNotifications]);

  return (
    <div className="device-indicators">
      {status ? (
        <span className="device-indicators__on" />
      ) : (
        <>
          <span className="device-indicators__last-time">{lastCheckTime}</span>
          <span className="device-indicators__off" />
        </>
      )}

      <div className={`device-indicators__wifi${ApdWifi ? '' : ' device-indicators__wifi--off'}`}>
        <WifiOutlined />
      </div>

      <div className="device-indicators__storage">
        <Tooltip title={usageProperties.message}>
          <DatabaseOutlined style={{ color: usageProperties.color }} />
        </Tooltip>
      </div>

      <div className="device-indicators__notifications">
        <Tooltip title={notificationStatus.message}>
          <BellOutlined style={{ color: notificationStatus.color }} />
        </Tooltip>
      </div>
    </div>
  );
};

export default memo(DeviceIndicators);
