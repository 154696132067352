// GLOBAL
export const GLOBAL_LOADING = 'GLOBAL_LOADING';

// AUTH
export const LOGIN = 'LOGIN';
export const GET_USER = 'GET_USER';
export const LOGOUT = 'LOGOUT';

// DEVICES
export const GET_DEVICES = 'GET_DEVICES';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const VIEW_DEVICE = 'VIEW_DEVICE';
export const SET_POWER_DEVICE = 'SET_POWER_DEVICE';
export const SET_STATE_DEVICE = 'SET_STATE_DEVICE';
export const DEVICE_STATUS = 'DEVICE_STATUS';
export const UPDATE_DEVICE_FROM_SOCKET = 'UPDATE_DEVICE_FROM_SOCKET';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const GET_SCANS = 'GET_SCANS';
export const SCAN_DEVICE = 'SCAN_DEVICE';
export const GET_SOCKET_SCAN = 'GET_SOCKET_SCAN';

// CONFIGS

export const CREATE_CONFIG = 'CREATE_CONFIG';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const DELETE_CONFIG = 'DELETE_CONFIG';

// ERRORS
export const ERRORS = 'ERRORS';

// USERS
export const GET_USERS = 'GET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

// KEYS
export const GET_KEYS = 'GET_KEYS';

// TERMINAL
export const SET_LOGS = 'SET_LOGS';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_INFO = 'SET_INFO';
export const CLEAR_TERMINAL = 'CLEAR_TERMINAL';
export const CLEAR_LOGS = 'CLEAR_LOGS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_INFO = 'CLEAR_INFO';
export const GET_TERMINAL_DATA_FROM_SESSION_STORAGE = 'GET_TERMINAL_DATA_FROM_SESSION_STORAGE';

// CLIENT KEY
export const GET_KEY = 'GET_KEY';
export const ADD_KEY = 'ADD_KEY';
export const UPDATE_KEY = 'UPDATE_KEY';
export const DELETE_KEY = 'DELETE_KEY';

// TEMPLATES_PAGE
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const SAVE_AS_TEMPLATE = 'SAVE_AS_TEMPLATE';
export const IMPORT_TEMPLATE = 'IMPORT_TEMPLATE';

// SOFTWARE PAGE
export const GET_SOFTWARE = 'GET_SOFTWARE';
export const DELETE_SOFTWARE = 'DELETE_SOFTWARE';
export const SET_SOFTWARE = 'SET_SOFTWARE';
export const IMPORT_SOFTWARE = 'IMPORT_SOFTWARE';
export const GET_APPLIED_SOFTWARE = 'GET_APPLIED_SOFTWARE';

// DEFAULT CLIENT
export const GET_DEFAULT_CLIENT = 'GET_DEFAULT_CLIENT';
export const UPDATE_DEFAULT_CLIENT = 'UPDATE_DEFAULT_CLIENT';

// CELL INFO
export const GET_CELL_INFO = 'GET_CELL_INFO';
export const SET_CELL_INFO = 'SET_CELL_INFO';

// USER SETTINGS PAGE
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
