import { GET_USER_SETTINGS } from '../../constants/actionTypes';

const initialState = {
  // userSettings: null,
};

export default function userSettings(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_SETTINGS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}
