import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SettingOutlined } from '@ant-design/icons';
import { Button, Input, Form, Switch } from 'antd';

import './userSettings.sass';

import { getUserSettings, setUserSettings } from '../../actions/userSettings.actions';

const UserSettings = ({ getUserSettings, setUserSettings }) => {
  const { Item } = Form;
  const [form] = Form.useForm();

  const [emailNotifications, setEmailNotification] = useState(false);
  const [browserNotifications, setBrowserNotification] = useState(false);
  const [email, setEmail] = useState('');
  const [initEmail, setInitEmail] = useState('');
  const [emailLoaded, setEmailLoaded] = useState(false);

  const sameInputValue = email === initEmail;

  const watchRules = [
    {
      type: 'email',
      message: 'The input is not a valid email address!',
    },
  ];

  const handleBrowserNotification = (value) => {
    setBrowserNotification(value);
    setUserSettings({ BrowserNotifications: value });
  };

  const handleEmailNotification = (value) => {
    setEmailNotification(value);
    setUserSettings({ EmailNotifications: value });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const cancelEditEmail = () => {
    setEmail(initEmail);
    form.setFieldsValue({ emailInput: initEmail });
  };

  const onFinish = (values) => {
    // Was used "emailInput", instead of "Email" - a unique name,
    // to help prevent the browser from autofill it with the wrong information.
    // Because was used type="email" it was autofilled with username used for login/
    const { emailInput } = values;
    setUserSettings({ Email: emailInput });
    setInitEmail(emailInput);
  };

  useEffect(() => {
    getUserSettings().then((res) => {
      setBrowserNotification(res.Data.BrowserNotifications);
      setEmailNotification(res.Data.EmailNotifications);
      setInitEmail(res.Data.Email);
      setEmail(res.Data.Email);
      setEmailLoaded(true);
    });
  }, [getUserSettings]);

  return (
    <div className="user-settings">
      <div className="title">
        <SettingOutlined /> &nbsp; User settings
      </div>
      <br />
      <Form onFinish={onFinish} form={form} className="settings-elem">
        <Item initialValue={browserNotifications} label="Browser notification:" name="BrowserNotifications">
          <Switch
            checked={browserNotifications}
            onChange={handleBrowserNotification}
            checkedChildren="on"
            unCheckedChildren="off"
          />
        </Item>

        <Item label="Email alerts:" name="EmailNotifications" initialValue={emailNotifications}>
          <Switch
            checked={emailNotifications}
            onChange={handleEmailNotification}
            checkedChildren="on"
            unCheckedChildren="off"
          />
        </Item>

        {emailLoaded && (
          <div className="settings-elem_row">
            <Item label="Email:" name="emailInput" initialValue={email} rules={watchRules}>
              <Input onChange={handleEmailChange} value={email} placeholder="example@email.com" name="emailInput" />
            </Item>
            <div className={`update-actions ${sameInputValue && 'disabled'}`}>
              <Button size="small" shape="round" onClick={cancelEditEmail}>
                Cancel
              </Button>
              <Button htmlType="submit" size="small" shape="round" type="primary">
                Submit
              </Button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  settings: state.userSettings,
});

export default connect(mapStateToProps, { getUserSettings, setUserSettings })(UserSettings);
