import React from 'react';

import { Switch, Route } from 'react-router';

import {
  ErrorPage,
  NotFoundPage,
  Settings,
  HomePage,
  DevicePage,
  ClientKeys,
  Templates,
  Software,
  UserSettings,
} from './containers';

import { routes } from './constants/routes';
import AuthRoute from './components/AuthRoute/AuthRoute';
import { MainLayout as Main, MinimalLayout as Minimal } from './layout';
import Login from './containers/loginPage/login';
import UnAuthRoute from './components/UnauthRoute/unAuthRoute';

const Routes = () => (
  <Switch>
    <AuthRoute component={HomePage} layout={Main} path={routes.HOME} exact />
    <AuthRoute component={ErrorPage} layout={Main} path={routes.ERROR} />
    <AuthRoute component={Settings} layout={Main} path={routes.SETTINGS} />
    <AuthRoute component={ClientKeys} layout={Main} path={routes.KEYS} />
    <AuthRoute component={Templates} layout={Main} path={routes.TEMPLATES} />
    <AuthRoute component={Software} layout={Main} path={routes.SOFTWARE} />
    <UnAuthRoute component={Login} layout={Minimal} path="/auth" />
    <AuthRoute component={DevicePage} layout={Main} path={routes.DEVICE} exact />
    <AuthRoute component={UserSettings} layout={Main} path={routes.USERSETTINGS} />
    <Route path="*" component={NotFoundPage} />
  </Switch>
);

export default Routes;
