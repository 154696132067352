import { axios } from './axiosConf';

export default {
  devices: {
    getDevices: () => axios.get('/listClients'),
    updateDevice: (device, data) => axios.post(`/updateClient?DeviceID=${device}`, data),
    viewDevice: (device) => axios.get(`/viewClient?DeviceID=${device}`),
    updateDefault: (device, data) => axios.post(`/updateDefault?DeviceID=${device}`, data),
    setPower: (device, power) => axios.post(`/setPower?DeviceID=${device}`, power),
    setState: (device, state) => axios.post(`/setState?DeviceID=${device}`, state),
    deleteDevice: (deviceId) => axios.delete(`/deleteClient?DeviceID=${deviceId}`),
    scan: (deviceId, advance) => axios.put(`/scanning/${deviceId}`, { advance }),
    getScans: (deviceId) => axios.get(`/scanning/${deviceId}`),
  },
  config: {
    createConfig: (deviceId, data) => axios.put(`/addConfigApp/${deviceId}`, data),
    updateConfig: (configId, data) => axios.put(`/updateConfigApp/${configId}`, data),
    deleteConfig: (configId) => axios.delete(`/deleteConfigApp/${configId}`),
  },
  // Test
  auth: {
    login: (data) => axios.post('/login', JSON.stringify(data)),
    getUser: () => axios.get('/getUser'),
  },
  users: {
    getUsers: () => axios.get('/listUsers'),
    addUser: (data) => axios.put('/addUser', data),
    updateUser: (userId, data) => axios.put(`/updateUser/${userId}`, data),
    deleteUser: (userId) => axios.delete(`/deleteUser/${userId}`),
  },
  keys: {
    getKeys: () => axios.get('/listKeys'),
  },
  clientKey: {
    getKey: (clientId) => axios.get(`/viewClientKey/${clientId}`),
    addKey: (clientId, data) => axios.put(`/addClientKey/${clientId}`, data),
    updateKey: (clientId, data) => axios.put(`/updateClientKey/${clientId}`, data),
    deleteKey: (clientId) => axios.delete(`/deleteClientKey/${clientId}`),
  },
  templates: {
    getTemplates: () => axios.get('/getTemplates'),
    deleteTemplate: (templateId) => axios.delete(`deleteTemplates/${templateId}`),
    exportTemplate: (templateId) => axios.post(`/exportTemplate/${templateId}`),
    exportTemplateFromDevice: (templateId) => axios.post(`/exportClient/${templateId}`),
    saveAsTemplate: (id, name) => axios.post(`/saveAsTemplate/${id}?Name=${name}`),
    importTemplate: (data) => axios.put('/importTemplate', data),
    setTemplateToClient: (deviceId, templateId) =>
      axios.post(`/setTemplateToDevice/${deviceId}?TemplateID=${templateId}`),
  },
  software: {
    get: () => axios.get('/software'),
    delete: (id) => axios.delete(`/software/${id}`),
    import: (data) => axios.put('/software', data),
    set: (deviceId, softwareId) => axios.put(`/software/${deviceId}`, { software_id: softwareId }),
    getApplied: (id) => axios.get(`/software/${id}`),
  },
  cellInfo: {
    get: (id) => axios.get(`/cellInfo/${id}`),
    set: (id) => axios.put(`/cellInfo/${id}`),
  },
  userSettings: {
    get: () => axios.get('/user-settings'),
    set: (data) => axios.post('/user-settings', data),
  },
};
